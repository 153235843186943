import '../assets/css/login.css';

import Formsy from 'formsy-react';
import { Link,navigate } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ButtonLoader from '../components/buttonLoader';
import ErrrorHandel from '../components/ErrrorHandel';
import MyInput from '../components/MyInput';
import bottomLine from '../images/login-line.svg';
import * as actionCreators from '../store/actions';
import {  formatQueryParams } from './../core/util'

import Layout from "../components/layout"
import SEO from "../components/seo"
import lockIcon from "../images/lock.svg"
import Footer from '../components/footer';
import lineImage from "../images/line2.png"

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            successMsg: '',
            token:'',
            tokenMsg: '',
            loginUrl:'',
            dealertoken:null,
            logoImg:null
        };
    }

    async componentDidMount() {
        const param = formatQueryParams(this.props.location.search);
        let dealertoken = null;
        if(param.dealertoken) dealertoken=param.dealertoken;
        if(param.token){
            this.setState({token: param.token,dealertoken:dealertoken});
            await this.getFromDirect(param.token,dealertoken);
        } else {
            navigate("/login"+(dealertoken?'/?dealertoken='+dealertoken:''))
        }
    }

    getFromDirect = async(paramId,dealertoken) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/checkForgotToken'+(dealertoken?'/?dealertoken='+dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    forgotToken: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ tokenMsg: 'Reset Password', loginUrl:res.loginurl,logoImg:res.logoImg })
                } else if(res.status === 2){
                    this.setState({ tokenMsg: 'Token Expired' })
                } else if(res.status === 3){
                    this.setState({ tokenMsg: 'Token Invalid' })
                } 
            });
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showButtonLoader, hideButtonLoader } = this.props;
        const {dealertoken} = this.state;
        showButtonLoader()
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/resetPassword'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                forgotToken:this.state.token,
                password:model.password
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 1){
                this.setState({successMsg: 'Password reset successfully'})
                this.refs['resetPasswordForm'].reset();
            } else if(res.status === 0){
                this.setState({ successMsg: 'Failed. Token Expired' })
            } 
            hideButtonLoader();
        });
        
    }

    close() {
        if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
            navigate(this.props.location.state.prevPath);
        } else {
            navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
    }

    render() {

        const { canSubmit, successMsg,tokenMsg,loginUrl,logoImg,dealertoken } = this.state;
        const { buttonLoader } = this.props;
        return (
            <div>
            <SEO title="Reset Password" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />

            <div className={dealertoken?"login-container grayish":"login-container"}>
                
                <div className="centre">
                <img className="logoside" src={logoImg} alt="" width="250" />

                    <Formsy ref="resetPasswordForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                        <ErrrorHandel formName={'resetPasswordForm'} self={this} />
                        <div className="sign-in-container">
                            <h2 className="title">{tokenMsg}</h2>
                            {tokenMsg==="Reset Password" ?
                            <div>
                            <div className="form-group error">
                                <img className="txticon" alt="password" src={lockIcon} />
                                <MyInput
                                    name="password"
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    value={''}
                                    className="form-control"
                                    isLabelRequired={true}
                                    validations={{
                                        minLength: 8
                                    }}
                                    validationError="Password must be 8 characters minimum."
                                    required
                                />
                            </div>
                            <div className="form-group error">
                            <img className="txticon" src={lockIcon} alt="password" />
                                <MyInput
                                    name="cPassword"
                                    id="cPassword"  
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={''}
                                    className="form-control"
                                    isLabelRequired={true}
                                    validations="equalsField:password"
                                    validationError="Password and password confimation do not match."
                                    required
                                />
                            </div>
                            <div className="form-group error">
                            <a className="forgot-pass" href={loginUrl} target="_blank">Login</a>
                            </div>

                            <span>{successMsg}</span>
                            <button className={!canSubmit ? 'btn disabled' : 'btn'} disabled={!canSubmit} type="submit">
                                <ButtonLoader isLoading={buttonLoader} label={'Reset'} />
                            </button>
                            </div>
                            : ''
                            }
                        </div>
                    </Formsy>
                    {/* <div className="login-line">
                        <img src={bottomLine} alt="line" />
                    </div> */}
                </div>
            </div>
            {/* <div className="break-section">
                    <img src={lineImage} alt="line" />
                </div> */}
            {/* <Footer /> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);